import React, { FC, PropsWithChildren } from 'react';
import { AlignContent } from '~/templates/blocks/components/AlignContent/AlignContent';
import { StyledHero, StyledContent, StyledMediaWrapper, Overlay, StyledAboveText } from './styled';
import { useTheme } from '@emotion/react';
import { Image } from '~/shared/components/Image/Image';
import { Video } from '~/shared/components/Video/Video';
import { Text } from '~/shared/components/Text/Text';
import { queries } from '~/theme/breakpoints';
import { ImageMedia, VideoMedia } from '~/lib';

export type PageHeroProps = PropsWithChildren<{
    theme?: 'Light' | 'Dark';
    backgroundColor?: string;
    video?: VideoMedia;
    image?: ImageMedia;
    imageAltText?: string;
    headline?: string;
    aboveText?: string;
    belowText?: string;
}>;

const PageHero: FC<PageHeroProps> = ({
    children,
    image,
    imageAltText,
    video,
    headline,
    backgroundColor,
    theme,
    aboveText,
    belowText,
}) => {
    const { sizes, colors } = useTheme();

    const mediaType = video ? 'video' : 'image';
    const imageSizes = {
        full: `${queries.xs} 100vw, ${queries.pageMax} 100vw, ${sizes.pageMaxWidth}px`,
        split: `${queries.xs} 100vw, ${queries.pageMax} 50vw, ${sizes.pageMaxWidth / 2}px`,
    };

    const textColor = theme === 'Light' ? colors.white : colors.black;
    const hasOverlay = theme === 'Light';

    return (
        <StyledHero priority backgroundColor={backgroundColor}>
            {hasOverlay && <Overlay />}

            <StyledContent mediaSize="full">
                <AlignContent maxWidth="100%" alignment="center" position="center">
                    {aboveText && (
                        <StyledAboveText>
                            <Text>{aboveText}</Text>
                        </StyledAboveText>
                    )}

                    {headline && (
                        <Text
                            variant="display0"
                            as="h1"
                            color={textColor}
                            css={{ wordBreak: 'break-word', textTransform: 'uppercase' }}
                            data-testid="page-hero-headline"
                        >
                            {headline}
                        </Text>
                    )}

                    <Text css={{ minHeight: '26px' }}>{belowText}</Text>

                    {children}
                </AlignContent>
            </StyledContent>

            {(image || video) && (
                <StyledMediaWrapper>
                    {mediaType === 'image' && image && (
                        <Image
                            data-test="page-hero-image"
                            src={image.src}
                            priority
                            alt={image.alt || imageAltText || image.name}
                            layout="fill"
                            objectFit="cover"
                            sizes={imageSizes['full']}
                            focalPoint={image.focalPoint}
                        />
                    )}

                    {mediaType === 'video' && video && (
                        <Video
                            muted
                            controls={false}
                            src={video.src}
                            loop={video.loop}
                            playing
                            posterSrc={image?.src}
                            cover
                        />
                    )}
                </StyledMediaWrapper>
            )}
        </StyledHero>
    );
};

export default PageHero;
