import { breakpoints } from '~/theme/breakpoints';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { StyledAlignContent } from '~/templates/blocks/components/AlignContent/styled';
import { M10HeroProps } from '~/templates/blocks/components/M10Hero/M10Hero';

type StyledM10ContentProps = {
    mediaSize: M10HeroProps['mediaLayout'];
};

export const StyledMediaWrapper = styled.div({
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
});

export const StyledHero = styled.div<
    Omit<M10HeroProps, 'id' | 'type' | 'mediaLayout' | 'mediaHorizontalAlignment'>
>(({ theme, backgroundColor }) => ({
    position: 'relative',
    width: '100%',
    display: 'grid',
    maxWidth: theme.sizes.pageMaxWidth,
    margin: '0 auto',
    backgroundColor,

    [`${StyledContent}`]: {
        [breakpoints.xs]: {
            padding: theme.space['3'],
        },
        padding: theme.space['4'],
        [breakpoints.md]: {
            padding: theme.space['6'],
        },
    },
    [`${SliderWrapper}`]: {
        paddingBottom: theme.space['4'],
        paddingLeft: 0,
        paddingRight: 0,
        [breakpoints.md]: {
            padding: theme.space['6'],
        },
        [breakpoints.xs]: {
            paddingBottom: theme.space['3'],
        },
    },
}));

export const SliderWrapper = styled.div({
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    right: 0,
});

export const StyledContent = styled.div<StyledM10ContentProps>(
    {
        position: 'relative',
        height: 'auto',
        maxHeight: 1200,
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        [breakpoints.xs]: {
            maxWidth: '100vw',
            minHeight: 256,
        },
        minHeight: 400,
    },

    switchProp('mediaSize', {
        full: {
            [`${StyledMediaWrapper}`]: {
                top: 0,
                left: 0,
                position: 'absolute',
            },
            [`${StyledAlignContent}`]: {
                width: '100%',
                margin: '0 auto',
                zIndex: 1,
            },
        },
    }),
);

export const Overlay = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    opacity: 0.25,
    background: `linear-gradient(165deg, ${theme.colors.black} 0%, rgba(0,0,0,0) 50%)`,
}));

export const StyledAboveText = styled.div(() => ({
    position: 'absolute',
    top: '50px',
    [breakpoints.sm]: {
        top: '85px',
    },
}));
