import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import React from 'react';
import { useToggleRefinement } from 'react-instantsearch';
import { useTranslation } from '~/shared/utils/translation';
import { RefinementDropdown } from './RefinementDropdown';
import { RefinementProps } from './RefinementProps';
import {
    DropdownItemText,
    DropdownItemContainer,
    DropDownItemButton,
    StyledCheckmark,
    FilterList,
    StyledCheckmarkContainer,
    DropdownTextWrapper,
    DropdownValueWrapper,
} from './styled';

type Props = RefinementProps;
type refinementState =
    import('instantsearch.js/es/connectors/toggle-refinement/connectToggleRefinement').ToggleRefinementRenderState;
type handleRefinementProps = Pick<refinementState, 'refine' | 'value'> & {
    legend: string;
};

export const RefinementNewsDropdown = ({ label }: Props) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const { filterEvent } = useEvents(frame);

    const { refine: isOnOfferRefine, value: isOnOfferValue } = useToggleRefinement({
        attribute: 'cust_onOffer.isOnOffer',
        on: true,
    });

    const { refine: isMemberRefine, value: isMemberValue } = useToggleRefinement({
        attribute: 'cust_onOffer.isMemberOffer',
        on: true,
    });

    // const { refine: activePromoRefine, value: activePromoValue } = useToggleRefinement({
    //     attribute: 'cust_activepromo.promotiontype',
    //     on: 'Julepris',
    // });

    const renderRefinement = ({ refine, value, legend }: handleRefinementProps) => {
        const handleRefine = () => {
            if (refine) {
                refine(value);
                filterEvent('Filtering', legend);
            }
        };

        return (
            <DropdownItemContainer grow>
                <DropDownItemButton
                    fullWidth
                    variant="plain"
                    isRefined={value.isRefined}
                    onClick={handleRefine}
                >
                    <DropdownItemText grow alignItems="space-between">
                        <DropdownTextWrapper> {legend}</DropdownTextWrapper>
                        <DropdownValueWrapper>
                            {value.count ? ` (${value.count})` : ''}
                            <StyledCheckmarkContainer>
                                {value.isRefined && <StyledCheckmark></StyledCheckmark>}
                            </StyledCheckmarkContainer>
                        </DropdownValueWrapper>
                    </DropdownItemText>
                </DropDownItemButton>
            </DropdownItemContainer>
        );
    };

    return (
        <RefinementDropdown label={label}>
            <FilterList column>
                {renderRefinement({
                    refine: isOnOfferRefine,
                    value: isOnOfferValue,
                    legend: translate('plp.onOffer'),
                })}

                {renderRefinement({
                    refine: isMemberRefine,
                    value: isMemberValue,
                    legend: translate('plp.onMemberOffer'),
                })}
                {/* 
                {renderRefinement({
                    refine: activePromoRefine,
                    value: activePromoValue,
                    legend: translate('plp.julepris'),
                })} */}
            </FilterList>
        </RefinementDropdown>
    );
};
