import { TranslationKey } from '~/lib';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import React from 'react';
import { useToggleRefinement } from 'react-instantsearch';
import { RefinementProps } from './RefinementProps';
import { RefinementDropdown } from './RefinementDropdown';
import {
    DropdownItemText,
    DropdownItemContainer,
    DropDownItemButton,
    FilterList,
    StyledCheckmark,
    DropdownTextWrapper,
    DropdownValueWrapper,
    StyledCheckmarkContainer,
} from './styled';

export type RefinementToggleProps = RefinementProps;

export const RefinementToggle = ({ attribute, label }: RefinementToggleProps) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const { filterEvent } = useEvents(frame);

    const { refine, value } = useToggleRefinement({
        attribute,
        on: true,
    });

    const handleRefine = () => {
        if (refine) {
            refine(value);
            filterEvent('Filtering', translate(`plp.facet.${attribute}` as TranslationKey));
        }
    };

    return (
        <RefinementDropdown label={label}>
            <FilterList column>
                <DropdownItemContainer grow>
                    <DropDownItemButton
                        fullWidth
                        variant="plain"
                        isRefined={value.isRefined}
                        onClick={handleRefine}
                    >
                        <DropdownItemText grow alignItems="flex-start">
                            <DropdownTextWrapper>{label}</DropdownTextWrapper>

                            <DropdownValueWrapper>
                                {value.count ? ` (${value.count})` : ''}
                                <StyledCheckmarkContainer>
                                    {value.isRefined && <StyledCheckmark></StyledCheckmark>}
                                </StyledCheckmarkContainer>
                            </DropdownValueWrapper>
                        </DropdownItemText>
                    </DropDownItemButton>
                </DropdownItemContainer>
            </FilterList>
        </RefinementDropdown>
    );
};
